
/*
 * SITE 
 * Page | Clinic 
 * 
 */

import { IModule, Page } from "@sygnal/sse";



export class ClinicPage implements IModule {

    constructor() {
    }

    setup() {
        
    }
    
    exec() {
                    
    }

}  

    
    
    
    